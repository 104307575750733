import React, { useState } from 'react';
import api from '../../components/api';

const ReportGenerator = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filterBy, setFilterBy] = useState('createdAt');

  const handleGenerateReport = () => {
    console.log('Generating report with:', { startDate, endDate, filterBy });

    api.post('/lesson/reporting/generate', { startDate, endDate, filterBy }, { responseType: 'blob' })
      .then(response => {
        console.log('Report generated:', response);

        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'report.pdf');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(err => {
        console.error('Error generating report:', err);
      });
  };

  return (
    <div className="p-8">
      <h2 className="text-3xl font-bold text-red-600 mb-4">Generate Report</h2>
      <div className="mb-4">
        <label className="block text-gray-700 mb-2">Start Date</label>
        <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} className="w-full border border-gray-300 rounded p-2" />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 mb-2">End Date</label>
        <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} className="w-full border border-gray-300 rounded p-2" />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 mb-2">Filter By</label>
        <select value={filterBy} onChange={(e) => setFilterBy(e.target.value)} className="w-full border border-gray-300 rounded p-2">
          <option value="createdAt">Upload Date</option>
          <option value="presentationDate">Presentation Date</option>
        </select>
      </div>
      <button onClick={handleGenerateReport} className="bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded">Generate Report</button>
    </div>
  );
};

export default ReportGenerator;
