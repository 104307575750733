import React, { useState } from 'react';
import api from '../../components/api';

const FileUpload = ({ currentPlatoon, currentPlatoonName }) => {
  const [file, setFile] = useState(null);
  const [lessonTitle, setLessonTitle] = useState('');
  const [datePresenting, setDatePresenting] = useState('');
  const [lessonNo, setLessonNo] = useState('1');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!file || !lessonTitle || !datePresenting) {
      return alert('Please fill out all fields');
    }

    const formData = new FormData();
    formData.append('title', lessonTitle);
    formData.append('lessonNo', lessonNo);
    formData.append('platoon', currentPlatoon);
    formData.append('presentationDate', datePresenting);
    formData.append('file', file);
    
    api.post('/lesson/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(res => {
      setFile(null);
      setLessonTitle('');
      setDatePresenting('');
      setLessonNo('1');
      alert('Lesson uploaded successfully!');
    })
    .catch((err) => {
      console.error(err);
      alert(`Error uploading lesson, screenshot for evidence.\nError Status: ${err}`);
    });
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">
        File Upload {currentPlatoonName ? `for ${currentPlatoonName}` : ''}
      </h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="lessonTitle" className="block text-gray-700 text-sm font-bold mb-2">Lesson Title</label>
          <input
            type="text"
            id="lessonTitle"
            value={lessonTitle}
            onChange={(e) => setLessonTitle(e.target.value)}
            className="shadow border border-red-600 w-full py-2 px-3 text-gray-700"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="datePresenting" className="block text-gray-700 text-sm font-bold mb-2">Date Presenting</label>
          <input
            type="date"
            id="datePresenting"
            value={datePresenting}
            onChange={(e) => setDatePresenting(e.target.value)}
            className="shadow border border-red-600 w-full py-2 px-3 text-gray-700"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="lessonNo" className="block text-gray-700 text-sm font-bold mb-2">Period No.</label>
          <select
            id="lessonNo"
            value={lessonNo}
            onChange={(e) => setLessonNo(e.target.value)}
            className="shadow border border-red-600 w-full py-2 px-3 text-gray-700"
          >
            <option value="1">Period 1</option>
            <option value="2">Period 2</option>
          </select>
        </div>
        <div className="mb-4">
          <label htmlFor="fileUpload" className="block text-gray-700 text-sm font-bold mb-2">Upload File</label>
          <input
            type="file"
            id="fileUpload"
            onChange={(e) => setFile(e.target.files[0])}
            className="shadow border border-red-600 w-full py-2 px-3 text-gray-700"
          />
        </div>
        <button type="submit" className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700">
          Submit
        </button>
      </form>
    </div>
  );
};

export default FileUpload;
