import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faDownload, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import EditLessonModal from '../../components/EditLessonModal.jsx';
import api from '../../components/api.js';

const MyUploadedLessons = ({ currentPlatoon, currentPlatoonName }) => {
    const [uploadedLessons, setUploadedLessons] = useState([]);
    const [selectedLesson, setSelectedLesson] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const getData = useCallback(() => {
        if (currentPlatoon) {
            setIsLoading(true);
            api.get('/lesson/my/' + currentPlatoon)
                .then(response => { 
                    setUploadedLessons(response.data);
                    setIsLoading(false);
                })
                .catch(err => {
                    console.error(err);
                    setIsLoading(false);
                });
        }
    }, [currentPlatoon]);

    useEffect(() => {
        getData();
    }, [currentPlatoon, getData]);

    const handleDelete = (id) => {
        api.delete(`/lesson/${id}`)
            .then(() => {
                setUploadedLessons(uploadedLessons.filter(lesson => lesson.id !== id));
                alert('Lesson deleted!');
            })
            .catch(err => {
                console.error(err);
                alert('Unable to delete lesson');
            });
    };

    const handleEdit = (lesson) => {
        setSelectedLesson(lesson);
        setIsModalOpen(true);
    };

    const handleSave = (updatedLesson) => {
        api.patch(`/lesson/${updatedLesson.id}`, updatedLesson)
            .then(() => {
                getData();
                setIsModalOpen(false);
                alert('Lesson updated!');
            })
            .catch(err => {
                console.error(err);
                alert('Unable to update lesson');
            });
    };

    const isDeletable = (uploadDate) => {
        const uploadedDate = new Date(uploadDate);
        const currentDate = new Date();
        const timeDiff = currentDate.getTime() - uploadedDate.getTime();
        const dayDiff = timeDiff / (1000 * 3600 * 24);
        return dayDiff <= 3;
    };

    const formattedDate = (lessonDate) => {
        const date = new Date(lessonDate);
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`;
    };

    return (
        <div>
            {currentPlatoon && currentPlatoonName ? (
                <h2 className="text-2xl font-bold mb-4">My Uploaded Lessons for {currentPlatoonName}</h2>
            ) : (
                <h2 className="text-2xl font-bold mb-4">My Uploaded Lessons</h2>
            )}
            {isLoading ? (
                <div>Loading...</div>
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 overflow-y-auto">
                    {uploadedLessons.map((lesson) => (
                        <div key={lesson.id} className="border shadow p-4 relative">
                            <h3 className="text-lg font-bold mt-2">{lesson.title}</h3>
                            <p className="text-sm text-gray-600">Upload Date: {formattedDate(lesson.createdAt)}</p>
                            <p className="text-sm text-gray-600">Date Presenting: {formattedDate(lesson.presentationDate)}</p>
                            <p className="text-sm text-gray-600">Period No.: {lesson.lessonNo}</p>
                            <div className="absolute top-2 right-2 text-gray-600 flex items-center space-x-2">
                                <button className="mr-2" onClick={() => handleEdit(lesson)}>
                                    <FontAwesomeIcon icon={faEdit} />
                                </button>
                                {isDeletable(lesson.createdAt) && (
                                    <button onClick={() => handleDelete(lesson.id)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                )}
                                <a
                                    href={`${api.getUri()}/lesson/file/static/${lesson.id}/${lesson.staticViewCode}`}
                                    download
                                    className="text-blue-600 hover:text-blue-800"
                                >
                                    <FontAwesomeIcon icon={faDownload} />
                                </a>
                                <a
                                    href={`/app/file/${lesson.id}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-600 hover:text-blue-800"
                                >
                                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {isModalOpen && (
                <EditLessonModal
                    lesson={selectedLesson}
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onSave={handleSave}
                />
            )}
        </div>
    );
};

export default MyUploadedLessons;