import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import CreateAccessCodeModal from '../../components/CreateAccessCodeModal';
import api from '../../components/api';
import { Menu, MenuItem, IconButton } from '@mui/material';

const AccessCodeManagement = ({ ranks, platoons }) => {
    const [accessCodes, setAccessCodes] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [allPlatoons, setAllPlatoons] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        api.get('/access')
            .then(response => setAccessCodes(response.data))
            .catch(err => console.error(err));

        api.get('/platoons/all')
            .then(response => setAllPlatoons(response.data))
            .catch(err => console.error(err));
    };

    const handleMenuOpen = (event, codeId) => {
        setAnchorEl({ ...anchorEl, [codeId]: event.currentTarget });
    };

    const handleMenuClose = (codeId) => {
        setAnchorEl({ ...anchorEl, [codeId]: null });
    };

    const handleDelete = (codeId) => {
        api.delete(`/access/${codeId}`)
            .then(() => {
                getData();
            })
            .catch(err => console.error(err));
    };

    const handleCreateCode = async (newCode) => {
        await api.post('/access', { data: newCode })
            .then(() => {
                getData();
            })
            .catch(err => console.error(err));
    };

    return (
        <div>
            <h2 className="text-2xl font-bold mb-4">Access Code Management</h2>
            <button onClick={() => setShowModal(true)} className="bg-red-600 hover:bg-red-700 text-white py-2 px-4 mb-4">Create Code</button>
            <table className="min-w-full bg-white border overflow-y-auto">
                <thead className="bg-red-600 text-white">
                    <tr>
                        <th className="py-2 px-4 border-b">Code</th>
                        <th className="py-2 px-4 border-b">Rank</th>
                        <th className="py-2 px-4 border-b">Uses</th>
                        <th className="py-2 px-4 border-b">Permissions</th>
                        <th className="py-2 px-4 border-b">Platoons</th>
                        <th className="py-2 px-4 border-b">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {accessCodes.map(code => (
                        <tr key={code.id}>
                            <td className="py-2 px-4 border-b">{code.code}</td>
                            <td className="py-2 px-4 border-b">{code.rankName}</td>
                            <td className="py-2 px-4 border-b">{code.uses}</td>
                            <td className="py-2 px-4 border-b">{Array.isArray(code.permissions) ? code.permissions.join(', ') : (Array.isArray(JSON.parse(code.permissions)) ? JSON.parse(code.permissions).join(', ') : 'Permissions Error')}</td>
                            <td className="py-2 px-4 border-b">{code.platoons.map((platoon) => platoon.platoon.name).join(', ')}</td>
                            <td className="py-2 px-4 border-b relative">
                                <IconButton onClick={(e) => handleMenuOpen(e, code.id)}>
                                    <FontAwesomeIcon icon={faEllipsisV} />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl && anchorEl[code.id]}
                                    open={Boolean(anchorEl && anchorEl[code.id])}
                                    onClose={() => handleMenuClose(code.id)}
                                >
                                    <MenuItem onClick={() => handleDelete(code.id)}>Delete</MenuItem>
                                </Menu>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {showModal && (
                <CreateAccessCodeModal
                    ranks={ranks}
                    platoons={allPlatoons}
                    onClose={() => setShowModal(false)}
                    onSave={handleCreateCode}
                />
            )}
        </div>
    );
};

export default AccessCodeManagement;
