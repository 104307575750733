import React, { useEffect, useState } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import LoginPage from './pages/unauth/Login.jsx';
import SignupPage from './pages/unauth/Signup.jsx';
import FileViewer from './pages/auth/FileViewer.jsx';
import Dashboard from './pages/auth/Dashboard.jsx';
import ErrorElement from './pages/unauth/Error.jsx';
import api from './components/api.js';
import { Toaster } from 'react-hot-toast';

function App() {
  const [user, setUser] = useState('loading');
  let globalVars = {
    appName: 'PHSACU',
    fullName: 'PHSACU (Pittwater House Army Cadet Unit)',
    backgroundUrl: '/newthumbnail.avif'
  }

  useEffect(() => {
    api.get('/auth/me')
      .then(res => {
        setUser(res.data);
      })
      .catch(err => {
        setUser(null);
        console.log('Failure to get user.')
        console.error(err);
      });
  }, []);

  const router = createBrowserRouter([
    {
      path: '/',
      element: <LoginPage user={user} globalVars={globalVars} />,
      errorElement: <ErrorElement globalVars={globalVars} />
    },
    {
      path: '/signup',
      element: <SignupPage user={user} globalVars={globalVars} />,
      errorElement: <ErrorElement globalVars={globalVars} />
    },
    {
      path: '/app',
      element: <Dashboard user={user} globalVars={globalVars} />,
      errorElement: <ErrorElement globalVars={globalVars} />
    },
    {
      path: '/app/:page',
      element: <Dashboard user={user} globalVars={globalVars} />,
      errorElement: <ErrorElement globalVars={globalVars} />
    },
    {
      path: '/app/file/:fileId',
      element: <FileViewer user={user} globalVars={globalVars} />,
      errorElement: <ErrorElement globalVars={globalVars} />
    },
  ]);

  return (
    <div className="App">
      <Toaster />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
