import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import api from '../../components/api';

const LessonViewer = ({ currentPlatoon }) => {
    const navigate = useNavigate();
    const [lessons, setLessons] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'descending' });

    useEffect(() => {
        setLessons([]);
        if (currentPlatoon !== null && currentPlatoon !== undefined) {
            api.get(`/lesson/all/${currentPlatoon}`)
                .then(response => {
                    setLessons(response.data);
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }, [currentPlatoon]);

    const sortedLessons = React.useMemo(() => {
        if (sortConfig.key) {
            return [...lessons].sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return lessons;
    }, [lessons, sortConfig]);

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        } else if (sortConfig.key === key && sortConfig.direction === 'descending') {
            direction = 'ascending';
        } else {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const handleRowClick = (lessonId) => {
        navigate(`/app/file/${lessonId}`);
    };

    const isReviewNeeded = (lesson) => {
        const presentingDate = new Date(lesson.presentationDate);
        const uploadDate = new Date(lesson.createdAt);
        const timeDiff = presentingDate.getTime() - uploadDate.getTime();
        const dayDiff = timeDiff / (1000 * 3600 * 24);
        return dayDiff <= 12;
    };

    const formattedDate = (lessonDate) => {
        const date = new Date(lessonDate);
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`;
    };

    return (
        <div>
            <h2 className="text-2xl font-bold mb-4">Lessons</h2>
            <table className="min-w-full bg-white border overflow-y-auto">
                <thead className="bg-red-600 text-white">
                    <tr>
                        {[
                            { key: 'title', label: 'Name' },
                            { key: 'presenter', label: 'Uploader' },
                            { key: 'presenterRank', label: 'Rank' },
                            { key: 'presentationDate', label: 'Date Presenting' },
                            { key: 'createdAt', label: 'Upload Date' },
                            { key: 'lessonNo', label: 'Period' }
                        ].map(({ key, label }) => (
                            <th
                                key={key}
                                onClick={() => requestSort(key)}
                                className="py-2 px-4 border-b cursor-pointer"
                            >
                                {label}
                                {sortConfig.key === key && (
                                    <FontAwesomeIcon icon={sortConfig.direction === 'ascending' ? faSortUp : faSortDown} className="ml-2" />
                                )}
                            </th>
                        ))}
                        <th className="py-2 px-4 border-b">Review</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedLessons.map((lesson) => (
                        <tr key={lesson.id} onClick={() => handleRowClick(lesson.id)} className="cursor-pointer">
                            <td className="py-2 px-4 border-b">
                                <a href={`/app/file/${lesson.id}`} target="_blank" rel="noopener noreferrer">
                                    {lesson.title}
                                </a>
                            </td>
                            <td className="py-2 px-4 border-b">{`${lesson.presenter.name.split(" ")[0]} ${lesson.presenter.email}`}</td>
                            <td className="py-2 px-4 border-b">{lesson.presenter.rankName}</td>
                            <td className="py-2 px-4 border-b">
                                {formattedDate(lesson.presentationDate)}
                            </td>
                            <td className="py-2 px-4 border-b">{formattedDate(lesson.createdAt)}</td>
                            <td className="py-2 px-4 border-b">{lesson.lessonNo}</td>
                            <td className="py-2 px-4 border-b">
                                {isReviewNeeded(lesson) ? <span className="text-red-600">&#x26A0;</span> : ''}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default LessonViewer;