import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import api from '../../components/api';
import Comments from '../../components/Comments';

const FileViewer = ({ globalVars, user }) => {
  const { fileId } = useParams();
  const navigate = useNavigate();
  const [lesson, setLesson] = useState(null);
  const [url, setUrl] = useState('');

  const getData = useCallback(() => {
    if (!fileId) {
      navigate('/app/lesson-viewer');
    }

    api.get(`/lesson/${fileId}`)
      .then(response => {
        setLesson(response.data);
        setUrl(`${api.getUri()}/lesson/file/static/${fileId}/${response.data.staticViewCode}`);
      })
      .catch(err => {
        console.error(err);
      });
  }, [fileId, navigate]);

  useEffect(() => {
    getData();
  }, [getData]);

  const goBack = () => {
    navigate('/app/lesson-viewer');
  };

  const downloadFile = () => {
    const link = document.createElement('a');
    link.href = url;
    link.download = lesson.name;
    link.click();
  };

  const deleteFile = () => {
    if (window.confirm('Are you sure you want to delete this lesson?')) {
      api.delete(`/lesson/${fileId}`)
        .then(() => {
          alert('Lesson deleted!');
          navigate('/app/lesson-viewer');
        })
        .catch(err => {
          console.error(err);
          alert('Unable to delete lesson');
        });
    }
  };

  if (!lesson) {
    return <div>Loading...</div>;
  }

  const docs = [(url !== null ? { uri: url } : null)];

  const formattedDate = (lessonDate) => {
    const date = new Date(lessonDate);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes() === 0 ? '00' : date.getMinutes()}`;
  };

  return (
    <div className="p-8">
      <button onClick={goBack} className="text-red-600 hover:underline mb-4">Back to Lesson Viewer</button>
      <button onClick={downloadFile} className="text-blue-600 hover:underline mb-4 ml-4">Download File</button>
      <button onClick={deleteFile} className="text-red-600 hover:underline mb-4 ml-4">Delete Lesson</button>
      <h2 className="text-3xl font-bold text-red-600 mb-4">{lesson.name}</h2>
      <div className="flex h-[85vh]">
        <div className="w-2/3 pr-4">
          <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} />
        </div>
        <div className="w-1/3 pl-4">
          <div className="mb-4">
            <h3 className="text-lg font-bold">Lesson Details</h3>
            <p><strong>Title:</strong> {lesson.title}</p>
            <p><strong>Uploader:</strong> {lesson.presenter.name}</p>
            <p><strong>Rank:</strong> {lesson.presenter.rankName}</p>
            <p><strong>Email:</strong> {lesson.presenter.email}</p>
            <p><strong>Upload Date:</strong> {formattedDate(lesson.createdAt)}</p>
            <p><strong>Presenting Date:</strong> {formattedDate(lesson.presentationDate)}</p>
            <p><strong>Period No.:</strong> {lesson.lessonNo}</p>
          </div>
          <Comments fileId={fileId} user={user} />
        </div>
      </div>
    </div>
  );
};

export default FileViewer;
