import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import EditUserModal from '../../components/EditUserModal';
import DeleteUserConfirmationModal from '../../components/DeleteUserConfirmationModal';
import api from '../../components/api';
import { Menu, MenuItem, IconButton, Modal, Box, TextField, Button, Typography } from '@mui/material';

const UserManagement = ({ ranks, platoons }) => {
    const [users, setUsers] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [editingUser, setEditingUser] = useState(null);
    const [userToDelete, setUserToDelete] = useState(null);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [passwordModalOpen, setPasswordModalOpen] = useState(false);
    const [passwordUser, setPasswordUser] = useState(null);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [allPlatoons, setAllPlatoons] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        api.get('/users/all')
            .then(response => setUsers(response.data))
            .catch(err => console.error(err));

        api.get('/platoons/all')
            .then(response => setAllPlatoons(response.data))
            .catch(err => console.error(err));
    };

    const handleMenuOpen = (event, userId) => {
        setAnchorEl({ ...anchorEl, [userId]: event.currentTarget });
    };

    const handleMenuClose = (userId) => {
        setAnchorEl({ ...anchorEl, [userId]: null });
    };

    const handleDelete = () => {
        api.delete('/users/' + userToDelete.id)
            .then(() => {
                alert('User successfully deleted.');
                getData();
                setConfirmationModalOpen(false);
            })
            .catch(err => console.error(err));
    };

    const handleSave = (updatedUser) => {
        api.patch(`/users/${updatedUser.id}`, {
            ...updatedUser,
            permissions: JSON.stringify(updatedUser.permissions)
        })
            .then(() => {
                alert('User successfully updated.');
                getData();
            })
            .catch(err => console.error(err));
    };

    const openEditModal = (user) => {
        setEditingUser(user);
    };

    const closeEditModal = () => {
        setEditingUser(null);
    };

    const openPasswordModal = (user) => {
        setPasswordUser(user);
        setPasswordModalOpen(true);
    };

    const closePasswordModal = () => {
        setPasswordUser(null);
        setPasswordModalOpen(false);
        setNewPassword('');
        setConfirmPassword('');
    };

    const openConfirmationModal = (user) => {
        setUserToDelete(user);
        setConfirmationModalOpen(true);
    };

    const closeConfirmationModal = () => {
        setUserToDelete(null);
        setConfirmationModalOpen(false);
    };

    const handleChangePassword = async () => {
        if (newPassword !== confirmPassword) {
            alert('Passwords do not match');
            return;
        }
        try {
            await api.patch(`/users/${passwordUser.id}/password`, { password: newPassword });
            closePasswordModal();
            alert('Password changed successfully!');
        } catch (error) {
            console.error('Error changing password:', error);
            alert('Error changing password.');
        }
    };

    const formattedDate = (lessonDate) => {
        const date = new Date(lessonDate);
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`;
    };

    return (
        <div>
            <h2 className="text-2xl font-bold mb-4">User Management</h2>
            <table className="min-w-full bg-white border overflow-y-auto">
                <thead className="bg-red-600 text-white">
                    <tr>
                        <th className="py-2 px-4 border-b">Name</th>
                        <th className="py-2 px-4 border-b">Email</th>
                        <th className="py-2 px-4 border-b">Rank</th>
                        <th className="py-2 px-4 border-b">Join Date</th>
                        <th className="py-2 px-4 border-b">Platoons</th>
                        <th className="py-2 px-4 border-b">Is Admin</th>
                        <th className="py-2 px-4 border-b">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => (
                        <tr key={user.id}>
                            <td className="py-2 px-4 border-b">{user.name}</td>
                            <td className="py-2 px-4 border-b">{user.email}</td>
                            <td className="py-2 px-4 border-b">{user.rankName}</td>
                            <td className="py-2 px-4 border-b">{formattedDate(user.createdAt)}</td>
                            <td className="py-2 px-4 border-b">{user.platoons.map((platoon) => platoon.platoon.name).join(', ')}</td>
                            <td className="py-2 px-4 border-b">
                                <input
                                    type="checkbox"
                                    checked={user.permissions.includes('admin')}
                                    disabled
                                />
                            </td>
                            <td className="py-2 px-4 border-b relative">
                                <IconButton onClick={(e) => handleMenuOpen(e, user.id)}>
                                    <FontAwesomeIcon icon={faEllipsisV} />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl && anchorEl[user.id]}
                                    open={Boolean(anchorEl && anchorEl[user.id])}
                                    onClose={() => handleMenuClose(user.id)}
                                >
                                    <MenuItem onClick={() => openEditModal(user)}>Edit</MenuItem>
                                    <MenuItem onClick={() => openPasswordModal(user)}>Change Password</MenuItem>
                                    <MenuItem onClick={() => openConfirmationModal(user)}>Delete</MenuItem>
                                </Menu>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {editingUser && (
                <EditUserModal
                    user={editingUser}
                    ranks={ranks}
                    platoons={allPlatoons}
                    onClose={closeEditModal}
                    onSave={handleSave}
                />
            )}
            {userToDelete && (
                <DeleteUserConfirmationModal
                    isOpen={confirmationModalOpen}
                    onClose={closeConfirmationModal}
                    onConfirm={handleDelete}
                    userName={userToDelete.name}
                />
            )}

            <Modal open={passwordModalOpen} onClose={closePasswordModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h6" component="h2">
                        Change Password for {passwordUser ? passwordUser.name : ''}
                    </Typography>
                    <TextField
                        label="New Password"
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Confirm Password"
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleChangePassword}
                        className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700"
                    >
                        Change Password
                    </Button>
                </Box>
            </Modal>
        </div>
    );
};

export default UserManagement;
