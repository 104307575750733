import React, { useState, useEffect } from 'react';
import { Modal, Box, TextField, Typography, Switch, FormControlLabel } from '@mui/material';
import api from '../../components/api';
import toast from 'react-hot-toast';

const Settings = ({ user }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [qstoreNotifications, setQstoreNotifications] = useState(false);
  const [lessonCommentNotifications, setLessonCommentNotifications] = useState(false);
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    // Fetch user data
    setName(user.name);
    setEmail(user.email);
    setQstoreNotifications(user.qstoreNotifications);
    setLessonCommentNotifications(user.lessonCommentNotifications);
  }, [user]);

  const handleSave = async () => {
    try {
      await api.patch('/users/update/me', {
        name,
        email,
        qstoreNotifications,
        lessonCommentNotifications
      });
      toast.success('Settings updated successfully!');
    } catch (error) {
      console.error('Error updating settings:', error);
      alert('Error updating settings.');
    }
  };

  const handlePasswordChange = async () => {
    if (newPassword !== confirmPassword) {
      alert('Passwords do not match');
      return;
    }
    try {
      await api.patch(`/users/${user.id}/password`, { password: newPassword });
      setPasswordModalOpen(false);
      setNewPassword('');
      setConfirmPassword('');
      toast.success('Password changed successfully!');
    } catch (error) {
      console.error('Error changing password:', error);
      alert('Error changing password.');
    }
  };

  return (
      <div>
        <h2 className="text-2xl font-bold text-red-600 mb-4">Settings</h2>
          <form onSubmit={(e) => { e.preventDefault(); handleSave(); }}>
            <div className="mb-4">
              <label htmlFor="name" className="block text-gray-700 text-sm font-bold mb-2">Name</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="shadow border border-red-600 w-full py-2 px-3 text-gray-700"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="shadow border border-red-600 w-full py-2 px-3 text-gray-700"
              />
            </div>
            <div className="mb-4">
              <FormControlLabel
                control={
                  <Switch
                    checked={qstoreNotifications}
                    onChange={(e) => setQstoreNotifications(e.target.checked)}
                    color="primary"
                  />
                }
                label="QStore Notifications"
              />
              <Typography variant="body2" color="textSecondary">
                Receive email notifications for unreviewed QStore requests every day at 4 PM.
              </Typography>
            </div>
            <div className="mb-4">
              <FormControlLabel
                control={
                  <Switch
                    checked={lessonCommentNotifications}
                    onChange={(e) => setLessonCommentNotifications(e.target.checked)}
                    color="primary"
                  />
                }
                label="Lesson Comment Notifications"
              />
              <Typography variant="body2" color="textSecondary">
                Receive email notifications when someone comments on your lesson.
              </Typography>
            </div>
            <button
              type="submit"
              className="bg-red-600 hover:bg-red-700 text-white font-bold w-full max-w-sm py-2 px-4 border border-red-700 shadow-lg focus:outline-none focus:shadow-outline"
            >
              Save
            </button>
          </form>

          <button
            variant="contained"
            color="secondary"
            onClick={() => setPasswordModalOpen(true)}
            className="mt-2 max-w-sm bg-transparent hover:bg-red-700 hover:text-white text-red-700 transition font-bold w-full py-2 px-4 border border-red-700 shadow-lg focus:outline-none focus:shadow-outline"
          >
            Change Password
          </button>

          <Modal open={passwordModalOpen} onClose={() => setPasswordModalOpen(false)}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
              }}
            >
              <Typography variant="h6" component="h2">
                Change Password
              </Typography>
              <TextField
                label="New Password"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Confirm Password"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                fullWidth
                margin="normal"
              />
              <button
                onClick={handlePasswordChange}
                className="bg-red-600 hover:bg-red-700 text-white font-bold w-full py-2 px-4 border border-red-700 shadow-lg focus:outline-none focus:shadow-outline"
              >
                Change Password
              </button>
            </Box>
          </Modal>
      </div>
  );
};

export default Settings;
