import React, { useState, useEffect, useCallback } from 'react';
import { Tabs, Tab, Box, Button, Table, TableBody, TableCell, TableHead, TableRow, Typography, TextField, Modal, Checkbox, FormControlLabel } from '@mui/material';
import DeleteUserConfirmationModal from '../../components/DeleteUserConfirmationModal';
import api from '../../components/api';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import toast from 'react-hot-toast';

const QStore = ({ user }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [subTab, setSubTab] = useState(0); // For managing sub-tabs in "Request Management"
  const [requests, setRequests] = useState([]);
  const [userRequests, setUserRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [declineReasonModalOpen, setDeclineReasonModalOpen] = useState(false);
  const [reviewReason, setReviewReason] = useState('');
  const [reportStartDate, setReportStartDate] = useState('');
  const [reportEndDate, setReportEndDate] = useState('');
  const [reportStatuses, setReportStatuses] = useState({
    unreviewed: true,
    approved: true,
    declined: true,
  });

  const [requestDate, setRequestDate] = useState('');
  const [item, setItem] = useState('');

  const fetchRequests = useCallback(async (state) => {
    try {
      const response = await api.get(`/qstore/requests/${state}`);
      setRequests(response.data);
    } catch (error) {
      console.error('Error fetching requests:', error);
    }
  }, []);

  const fetchUserRequests = useCallback(async () => {
    try {
      const response = await api.get(`/qstore/requests/user/${user.id}`);
      setUserRequests(response.data);
    } catch (error) {
      console.error('Error fetching user requests:', error);
    }
  }, [user.id]);

  useEffect(() => {
    fetchRequests('unreviewed'); // Start with unreviewed requests
    fetchUserRequests();
  }, [fetchRequests, fetchUserRequests]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setSubTab(0); // Reset sub-tab when switching main tab
    if (newValue === 0) {
      fetchUserRequests();
    } else {
      fetchRequests('unreviewed');
    }
  };

  const handleSubTabChange = (event, newValue) => {
    setSubTab(newValue);
    if (newValue === 0) fetchRequests('unreviewed');
    if (newValue === 1) fetchRequests('approved');
    if (newValue === 2) fetchRequests('declined');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await api.post('/qstore/requests', {
        request: item,
        date: requestDate,
        requestedById: user.id,
      });
      setRequestDate('');
      setItem('');
      fetchUserRequests(); // Refresh user's requests
      toast.success(`Request submitted`);
    } catch (error) {
      console.error('Error submitting request:', error);
      //if error state is 400, tell user the data.error
      if (error.response.status === 400) {
        toast.error(error.response.data.error);
      } else {
        toast.error('Error submitting request.');
      }
    }
  };

  const handleApprove = async (requestId) => {
    try {
      await api.patch(`/qstore/requests/${requestId}`, {
        reviewState: 2,
        reviewerId: user.id,
      });
      fetchRequests(subTab === 0 ? 'unreviewed' : subTab === 1 ? 'approved' : 'declined');
      toast.success(`Request ${requestId} approved`);
    } catch (error) {
      console.error('Error approving request:', error);
    }
  };

  const handleDecline = async (requestId) => {
    setSelectedRequest(requestId);
    setDeclineReasonModalOpen(true);
  };

  const submitDeclineReason = async () => {
    try {
      await api.patch(`/qstore/requests/${selectedRequest}`, {
        reviewState: 3,
        reviewerId: user.id,
        reviewReason,
      });
      fetchRequests(subTab === 0 ? 'unreviewed' : subTab === 1 ? 'approved' : 'declined');
      setDeclineReasonModalOpen(false);
      setReviewReason('');
      toast.success(`Request ${selectedRequest} declined`);
    } catch (error) {
      console.error('Error declining request:', error);
      toast.error('Error declining request');
    }
  };

  const handleDelete = async (requestId) => {
    setConfirmationModalOpen(false);
    try {
      await api.delete(`/qstore/requests/${requestId}`);
      fetchRequests(subTab === 0 ? 'unreviewed' : subTab === 1 ? 'approved' : 'declined');
      toast.success(`Request ${requestId} deleted`);
    } catch (error) {
      console.error('Error deleting request:', error);
      toast.error('Error deleting request');
    }
  };

  const handleRetract = async (requestId) => {
    try {
      await api.delete(`/qstore/requests/${requestId}`);
      fetchUserRequests();
      toast.success(`Request ${requestId} retracted`);
    } catch (error) {
      console.error('Error retracting request:', error);
      toast.error('Error retracting request');
    }
  };

  const openConfirmationModal = (request) => {
    setSelectedRequest(request);
    setConfirmationModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setSelectedRequest(null);
    setConfirmationModalOpen(false);
  };

  const openReportModal = () => {
    setReportModalOpen(true);
  };

  const closeReportModal = () => {
    setReportModalOpen(false);
  };

  const closeDeclineReasonModal = () => {
    setSelectedRequest(null);
    setDeclineReasonModalOpen(false);
    setReviewReason('');
  };

  const handleGenerateReport = async () => {
    const selectedStatuses = [];
    if (reportStatuses.unreviewed) selectedStatuses.push(1);
    if (reportStatuses.approved) selectedStatuses.push(2);
    if (reportStatuses.declined) selectedStatuses.push(3);
  
    try {
      const response = await api.post('/qstore/report', {
        startDate: reportStartDate,
        endDate: reportEndDate,
        statuses: selectedStatuses,
      }, {
        responseType: 'blob'
      });
  
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'qstore-report.pdf');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error generating report:', error);
    }
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: '#d32f2f', // Red color
      },
      secondary: {
        main: '#ff1744', // Light red color
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Modal open={reportModalOpen} onClose={closeReportModal}>
          <Box sx={{ p: 4, backgroundColor: 'white', margin: 'auto', mt: '10%', width: '50%' }}>
            <Typography variant="h6" gutterBottom>
              Generate Report
            </Typography>
            <TextField
              label="Start Date"
              type="date"
              value={reportStartDate}
              onChange={(e) => setReportStartDate(e.target.value)}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="End Date"
              type="date"
              value={reportEndDate}
              onChange={(e) => setReportEndDate(e.target.value)}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Box>
              <FormControlLabel
                control={<Checkbox checked={reportStatuses.unreviewed} onChange={(e) => setReportStatuses({ ...reportStatuses, unreviewed: e.target.checked })} />}
                label="Unreviewed"
              />
              <FormControlLabel
                control={<Checkbox checked={reportStatuses.approved} onChange={(e) => setReportStatuses({ ...reportStatuses, approved: e.target.checked })} />}
                label="Approved"
              />
              <FormControlLabel
                control={<Checkbox checked={reportStatuses.declined} onChange={(e) => setReportStatuses({ ...reportStatuses, declined: e.target.checked })} />}
                label="Declined"
              />
            </Box>
            <Button onClick={handleGenerateReport} variant="contained" color="primary" sx={{ mt: 2 }}>
              Generate
            </Button>
          </Box>
        </Modal>

        <Modal open={declineReasonModalOpen} onClose={closeDeclineReasonModal}>
          <Box sx={{ p: 4, backgroundColor: 'white', margin: 'auto', mt: '10%', width: '50%' }}>
            <Typography variant="h6" gutterBottom>
              Decline Reason
            </Typography>
            <TextField
              label="Reason"
              value={reviewReason}
              onChange={(e) => setReviewReason(e.target.value)}
              fullWidth
              margin="normal"
            />
            <Button onClick={submitDeclineReason} variant="contained" color="primary" sx={{ mt: 2 }}>
              Submit
            </Button>
          </Box>
        </Modal>

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Tabs value={selectedTab} onChange={handleTabChange}>
            <Tab label="Request an Item" />
            {user.permissions.includes('manageqstore') && <Tab label="Request Management" />}
          </Tabs>
          {user.permissions.includes('manageqstore') && (
            <Button onClick={openReportModal} variant="contained" sx={{ mt: 2 }}>
              Generate Report
            </Button>
          )}
        </Box>

        {selectedTab === 0 && (
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Typography variant="h6" gutterBottom>
              Request an Item
            </Typography>
            <TextField
              label="Request For"
              type="date"
              value={requestDate}
              onChange={(e) => setRequestDate(e.target.value)}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="Item"
              value={item}
              onChange={(e) => setItem(e.target.value)}
              fullWidth
              margin="normal"
            />
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>

            <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
              Your Requests
            </Typography>
            <Table sx={{ mt: 2 }}>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Item</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userRequests.map((request) => (
                  <TableRow key={request.id}>
                    <TableCell>{request.id}</TableCell>
                    <TableCell>{new Date(request.date).toLocaleDateString()}</TableCell>
                    <TableCell>{request.request}</TableCell>
                    <TableCell>{request.reviewState === 1 ? 'Pending' : request.reviewState === 2 ? 'Approved' : 'Declined'}</TableCell>
                    <TableCell>
                      {request.reviewState === 1 && (
                        <Button onClick={() => handleRetract(request.id)}>Retract</Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        )}

        {selectedTab === 1 && user.permissions.includes('manageqstore') && (
          <Box>
            <Tabs value={subTab} onChange={handleSubTabChange} sx={{ mt: 2 }}>
              <Tab label="Unreviewed Requests" />
              <Tab label="Approved Requests" />
              <Tab label="Declined Requests" />
            </Tabs>

            <Table sx={{ mt: 2 }}>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Item</TableCell>
                  <TableCell>Requested By</TableCell>
                  <TableCell>Review State</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {requests.map((request) => (
                  <TableRow key={request.id}>
                    <TableCell>{request.id}</TableCell>
                    <TableCell>{new Date(request.date).toLocaleDateString()}</TableCell>
                    <TableCell>{request.request}</TableCell>
                    <TableCell>{`${request.requestedBy.rankName} ${request.requestedBy.name} (${request.requestedBy.email})`}</TableCell>
                    <TableCell>{request.reviewState === 1 ? 'Pending' : request.reviewState === 2 ? 'Approved' : 'Declined'}{request.reviewState !== 1 && ` by ${request.reviewer !== null && `${request.reviewer.rankName} ${request.reviewer.name} (${request.reviewer.email})`}`}{request.reviewState === 3 && request.reviewReason !== null && request.reviewReason !== '' && (<div><br/>{`Reason: ${request.reviewReason}`}</div>)}</TableCell>
                    <TableCell>
                      <Button onClick={() => handleApprove(request.id)}>Approve</Button>
                      <Button onClick={() => handleDecline(request.id)}>Decline</Button>
                      <Button onClick={() => openConfirmationModal(request)}>Delete</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            {selectedRequest && (
              <DeleteUserConfirmationModal
                isOpen={confirmationModalOpen}
                onClose={closeConfirmationModal}
                onConfirm={() => handleDelete(selectedRequest.id)}
                userName={selectedRequest.request}
              />
            )}
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default QStore;
