import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import api from '../../components/api';

const PlatoonManagement = ({ ranks }) => {
    const [platoons, setPlatoons] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedPlatoon, setSelectedPlatoon] = useState(null);
    const [confirmationCooldown, setConfirmationCooldown] = useState(5);
    const [newPlatoonName, setNewPlatoonName] = useState('');

    useEffect(() => {
        getPlatoons();
    }, []);

    const getPlatoons = () => {
        api.get('/platoons/all')
            .then(response => {
                setPlatoons(response.data);
            })
            .catch(err => {
                console.error(err);
            });
    };

    const handleDelete = () => {
        if (confirmationCooldown > 0) return;

        api.delete(`/platoons/${selectedPlatoon.id}`)
            .then(() => {
                getPlatoons();
                setShowDeleteModal(false);
            })
            .catch(err => {
                console.error(err);
            });
    };

    const handleCreate = () => {
        if (!newPlatoonName) return;

        api.post('/platoons', { name: newPlatoonName })
            .then(() => {
                getPlatoons();
                setShowCreateModal(false);
                setNewPlatoonName('');
            })
            .catch(err => {
                console.error(err);
            });
    };

    const handleEdit = () => {
        if (!newPlatoonName) return;

        api.patch(`/platoons/${selectedPlatoon.id}`, { name: newPlatoonName })
            .then(() => {
                getPlatoons();
                setShowEditModal(false);
                setNewPlatoonName('');
            })
            .catch(err => {
                console.error(err);
            });
    };

    const openDeleteModal = (platoon) => {
        setSelectedPlatoon(platoon);
        setShowDeleteModal(true);
        setConfirmationCooldown(5);
        const interval = setInterval(() => {
            setConfirmationCooldown(prev => {
                if (prev === 1) clearInterval(interval);
                return prev - 1;
            });
        }, 1000);
    };

    const openEditModal = (platoon) => {
        setSelectedPlatoon(platoon);
        setNewPlatoonName(platoon.name);
        setShowEditModal(true);
    };

    return (
        <div>
            <h2 className="text-2xl font-bold mb-4">Platoon Management</h2>
            <button onClick={() => setShowCreateModal(true)} className="bg-red-600 hover:bg-red-700 text-white py-2 px-4 mb-4">
                <FontAwesomeIcon icon={faPlus} /> Create Platoon
            </button>
            <table className="min-w-full bg-white border overflow-y-auto">
                <thead className="bg-red-600 text-white">
                    <tr>
                        <th className="py-2 px-4 border-b">Platoon Name</th>
                        <th className="py-2 px-4 border-b">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {platoons.map(platoon => (
                        <tr key={platoon.id}>
                            <td className="py-2 px-4 border-b">{platoon.name}</td>
                            <td className="py-2 px-4 border-b">
                                <button onClick={() => openEditModal(platoon)} className="text-gray-600 hover:text-gray-800 mr-2">
                                    <FontAwesomeIcon icon={faEdit} />
                                </button>
                                <button onClick={() => openDeleteModal(platoon)} className="text-red-600 hover:text-red-800">
                                    <FontAwesomeIcon icon={faTrash} />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {showDeleteModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded shadow-xl w-96">
                        <h3 className="text-xl font-bold mb-4">Delete Platoon</h3>
                        <p>Are you sure you want to delete the platoon "{selectedPlatoon.name}"? This action will delete all associated lessons and files.</p>
                        <div className="flex justify-end mt-4">
                            <button onClick={() => setShowDeleteModal(false)} className="bg-gray-500 hover:bg-gray-600 text-white py-2 px-4 rounded mr-2">Cancel</button>
                            <button onClick={handleDelete} className={`bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded ${confirmationCooldown > 0 ? 'opacity-50 cursor-not-allowed' : ''}`} disabled={confirmationCooldown > 0}>
                                {confirmationCooldown > 0 ? `Wait ${confirmationCooldown}s` : 'Delete'}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {showCreateModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded shadow-xl w-96">
                        <h3 className="text-xl font-bold mb-4">Create Platoon</h3>
                        <div className="mb-4">
                            <label className="block text-gray-700">Platoon Name</label>
                            <input 
                                type="text" 
                                value={newPlatoonName}
                                onChange={(e) => setNewPlatoonName(e.target.value)}
                                className="w-full border border-gray-300 rounded p-2"
                            />
                        </div>
                        <div className="flex justify-end">
                            <button onClick={() => setShowCreateModal(false)} className="bg-gray-500 hover:bg-gray-600 text-white py-2 px-4 rounded mr-2">Cancel</button>
                            <button onClick={handleCreate} className="bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded">Create</button>
                        </div>
                    </div>
                </div>
            )}

            {showEditModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded shadow-xl w-96">
                        <h3 className="text-xl font-bold mb-4">Edit Platoon</h3>
                        <div className="mb-4">
                            <label className="block text-gray-700">Platoon Name</label>
                            <input 
                                type="text" 
                                value={newPlatoonName}
                                onChange={(e) => setNewPlatoonName(e.target.value)}
                                className="w-full border border-gray-300 rounded p-2"
                            />
                        </div>
                        <div className="flex justify-end">
                            <button onClick={() => setShowEditModal(false)} className="bg-gray-500 hover:bg-gray-600 text-white py-2 px-4 rounded mr-2">Cancel</button>
                            <button onClick={handleEdit} className="bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded">Save</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PlatoonManagement;
