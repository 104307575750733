import React from 'react';
import { useRouteError, Link } from 'react-router-dom';
import CopyrightFooter from '../../components/CopyrightFooter';

const ErrorElement = ({ globalVars }) => {
    const error = useRouteError();
    
    return (
        <div className="relative w-full h-screen overflow-hidden">
            {/* Background Image */}
            <img src={globalVars.backgroundUrl} alt="Background" className="absolute top-0 left-0 w-full h-full object-cover z-0 brightness-[75%] blur-[3px]" />
            
            {/* Main Content */}
            <div className="relative z-10 flex justify-center items-center w-full h-full">
                <div className="bg-white px-8 py-5 border-red-600 border-[1px] shadow-xl md:max-w-xl w-full mx-2">
                    <h1 className="text-4xl font-bold text-red-600">{error.status || 'Unexpected Error'}</h1>
                    <p className="mb-6 text-gray-700">
                        {error.statusText || error.message || 'An error occurred.'}
                    </p>
                    <Link to="/" className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 shadow-lg focus:outline-none focus:shadow-outline">
                        Go to Homepage
                    </Link>
                </div>
            </div>
            <CopyrightFooter fullName={globalVars.fullName} className='fixed w-full text-center bottom-0 left-0' />
        </div>
    );
}

export default ErrorElement;
