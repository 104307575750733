import axios from 'axios';

axios.defaults.withCredentials = true;

const api = axios.create({
  baseURL: 'https://phsacu.mannabeach.com/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

export default api;
