import React, { useEffect, useState, useCallback } from 'react';
import api from './api';

const Comments = ({ fileId, user }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');

  const getData = useCallback(() => {
    api.get(`/lesson/${fileId}`)
      .then(response => {
        setComments(response.data.comments);
      })
      .catch(err => {
        console.error(err);
      });
  }, [fileId]);

  const handleAddComment = (e) => {
    e.preventDefault();  // Prevents page refresh
    api.post('/lesson/comments', { lessonId: fileId, content: newComment })
      .then(response => {
        getData();
        setNewComment('');
      })
      .catch(err => {
        console.error(err);
      });
  };

  const handleDeleteComment = (commentId) => {
    api.delete(`/lesson/comments/${commentId}`)
      .then(() => {
        setComments(comments.filter(comment => comment.id !== commentId));
      })
      .catch(err => {
        console.error(err);
      });
  };

  const formattedDate = (lessonDate) => {
    const date = new Date(lessonDate);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes() === 0 ? '00' : date.getMinutes()}`;
  };

  const formatCommentContent = (content) => {
    return content.split('\n').map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  };

  useEffect(() => {
    getData();
  }, [fileId, getData]);

  return (
    <div className="mb-4">
      <h3 className="text-lg font-bold">Comments</h3>
      <div>
        {comments.map(comment => (
          <div key={comment.id} className="mb-2 border border-gray-200 p-2 rounded-sm">
            <div className='flex items-center gap-2'>
              <p className='text-xs'><b>{comment.user.name} @ {formattedDate(comment.createdAt)}</b></p>
              {comment.userId === user.id && (
                <button onClick={() => handleDeleteComment(comment.id)} className="text-red-600 hover:underline">Delete</button>
              )}
            </div>
            <p>{formatCommentContent(comment.content)}</p>
          </div>
        ))}
      </div>
      <form onSubmit={handleAddComment}>
        <textarea
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          className="w-full border border-gray-300 rounded p-2 mb-2"
        ></textarea>
        <button type="submit" className="bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded">Add Comment</button>
      </form>
    </div>
  );
};

export default Comments;
